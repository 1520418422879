<template>
	<div class="m-inf-scroll">
        <!-- 展示列表 -->
       <slot></slot>
       <!-- 加载中的动画 -->
       <slot name="loading" v-if="isloading&&!showstatus"></slot>
       <!-- 加载完成的动画 -->
       <slot name="nomore" v-if="showstatus"></slot>
       <!-- 回到顶部 -->
       <div class="back-top" v-if="isshowTop" @click="backTopFun"></div>
	</div>
</template>

<script>
/*
 * 触底加载 - 滚动监听
*/
var scrollTop;
var scrollHeight;
// 变量 windowHeight 是可视区的高度
var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
	export default {
		name: 'infinite-scroll',
        props:{
            forbid:{    //是否禁止使用触底加载
                type:Boolean,
                value:false
            },
            reserve:{   //预留高度（提前高度触发加载）
                type:Number,
                default:30
            },
            finish:{
                type:Boolean,  //是否完成全部数据加载（或暂无更多数据）
                value:false
            },
            finishload:{
                type:Boolean,  //是否完成当前页加载
                value:true
            }
        },
		data() {
			return {
                isloading:false,     //是否展示加载
                nomore:false,        //是否完成加载
                isshowTop:false      //返回顶部
			};
		},
        computed:{
            showstatus:function(){
                return this.finish;
            }
        },
        mounted(){
            this.$nextTick(()=>{
                var _that = this;
                var _reserve = this.reserve;
                var isload= false;
                console.log(this.forbid)
                if(this.forbid) return;
                //当前是超屏&&是否存在下一页
                // 变量 scrollHeight 是滚动条的总高度
                // scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // console.log()
                // if(scrollHeight<=windowHeight) return;
                //当前是否存在更多数据
                if(this.finish){
                    this.isloading = false;
                    this.nomore = true;
                    return;
                }
                //超屏幕(展示加载)
                this.isloading = true;
                this.nomore = false;

                //滚动监听
                window.onscroll = () => {
                    //禁止加载
                    if(_that.forbid) return;
                    windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                    // 变量 scrollTop 是滚动条滚动时, 距离顶部的距离
                    scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    // 变量 scrollHeight 是滚动条的总高度
                    scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                    if((scrollTop+_reserve)>=scrollHeight-windowHeight){  //达到触发区域
                        //阻止重复加载
                        if(!isload&&!_that.finishload) return;
                        isload = false;
                        //开始加载
                        _that.loadmore()
                    }else{
                        isload = true;
                    }
                    //返回顶部
                    if(scrollTop>windowHeight){
                        //显示顶部
                        this.isshowTop = true;
                    }else{
                        this.isshowTop = false;
                    }
                }
            })
        },
		methods: {
            //触底加载回调执行
            loadmore(){
                console.log('触底加载')
                this.$emit('Pullupload');
            },
            //返回顶部
            backTopFun(){
                var x = 400;
                var timer = setInterval(function(){
                    x=x-20;
                if(x<20){
                    x=0;
                    window.scrollTo(x,x);
                    clearInterval(timer);
                }
                window.scrollTo(x,x);
                },10);
                // document.body.scrollTop = 0;
                // document.documentElement.scrollTop = 0;
            }
		},
	}
</script>
<style lang="less">
.back-top{
    display: none;
    width: 0;
    height: 0;
}
@media screen and (max-width: 750px) {
    .back-top{
        display: block;
        position: fixed;
        bottom: 60px;
        right: 30px;
        z-index: 9;
        width: 40px;
        height: 40px;
        background: url(../assets/mobile/fanhui.png) no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
    }
}
</style>
