<template>
  <div class="m-img-preview" v-if="isshowdon">
    <div class="img-mask">
      <div class="img-contbox">
        <!-- <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item in imgPrevie.photo" :key="item">
                            <div class="img-wrap">
                                <img v-lazy="item" />
                            </div>
                        </div>
                    </div>
                </div> -->
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            class="swiper-slide"
            v-for="item in imgPrevie.photo"
            :key="item"
          >
            <div class="img-wrap">
              <img v-lazy="item" />
            </div>
          </swiper-slide>
        </swiper>
        <!-- pc操作 -->
        <div class="pc-opera">
          <!-- 如果需要导航按钮 -->
          <div class="swiper-button-prev button-opera f-ibm"></div>
          <div class="swiper-button-next button-opera f-ibm"></div>
          <div class="swiper-num f-ibm">
            第 <span class="red">{{ imgIndex }}</span> 张 （共{{
              imgPrevie.photo.length
            }}张）
          </div>
          <div class="imgpre-close f-ibm" @click="handlecloseFun"></div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <!-- h5 -->
      <div class="h5-show h5-imgpre-close" @click="handlecloseFun"></div>
      <div class="h5-show h5-opera">
        <div class="img-intro f-toe2">
          {{ imgPrevie.title }}
        </div>
        <div class="sj-intro">
          <div class="avtar f-ibm">
            <img v-lazy="imgPrevie.head_img" alt="" />
          </div>
          <div class="rit f-ibm">
            <div class="text f-toe f-ibm">{{ imgPrevie.name }}</div>
          </div>
          <router-link
            class="f-fr f-ibm"
            :to="'/popularBusiness/business?id=' + imgPrevie.user_business_id"
          >
            <div class="btn f-ibm">进入店铺</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from 'swiper';
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

// import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'
var preSwiper;
var _mythis;
//import 'swiper/dist/css/swiper.css';
export default {
  name: "imgPreview",
  props: {
    imgPrevie: {
      //预览数据
      type: Object,
      default: () => {},
    },
    isshowdon: {
      //是否开启预览
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      imgIndex: 1, //预览当前索引
      btmload: {
        isstar: false, //是否初始化加载
        isload: false, //加载动画
        stauts: 1, //加载状态 1开始加载 2加载完成 3暂无更多数据 4加载失败
      },
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        on: {
          slideChangeTransitionEnd: function () {
            _mythis.imgIndex = this.activeIndex + 1;
          },
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    _mythis = this;
  },
  mounted() {
    // var _that = this;
    // this.$nextTick(()=>{
    //     preSwiper = new Swiper('.swiper-container', {
    //         navigation: {
    //         nextButton: '.swiper-button-next',
    //         prevButton: '.swiper-button-prev',
    //         },
    //         onSlideChangeEnd:function(index){
    //             _that.imgIndex = index;
    //         }
    //     })
    // })
    // console.log(this.$refs.mySwiper.$swiper);
  },
  watch: {
    // 'isshowdon':function(newData,oldData){
    //     //是否初始化（打开重新初始化）
    //     if(newData){
    //         this.$nextTick(()=>{
    //             //是否需要重新初始化
    //             var _that = this;
    //             preSwiper = new Swiper('.swiper-container', {
    //                 navigation: {
    //                 },
    //                 nextButton: '.swiper-button-next',
    //                 prevButton: '.swiper-button-prev',
    //                 onSlideChangeEnd:function(res){
    //                     _that.imgIndex = (res.activeIndex+1);
    //                 }
    //             })
    //         })
    //     }
    // }
  },
  methods: {
    //关闭预览
    handlecloseFun() {
      this.$emit("closePreview");
    },
  },
};
</script>
<style lang="less">
.my-button-disabled {
  opacity: 0.2;
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.m-img-preview {
  .img-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
  }
  .img-contbox {
    width: 50%;
    max-height: 758px;
    border-radius: 20px;
    overflow: hidden;
    background-color: white;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    left: 50%;
    padding: 15px;
    box-sizing: border-box;
    transform: translateX(-50%) translateY(-50%);
    padding-bottom: 60px;
    box-sizing: border-box;
    .button-opera {
      position: relative;
      left: 0;
      top: 0;
      margin: 0;
      width: 28px;
      height: 28px;
    }
    .swiper-button-prev {
      background-image: url(../assets/pc/arrow_left@2x.png);
      background-repeat: no-repeat;
      background-size: 28px 28px;
    }
    .swiper-button-next {
      background-image: url(../assets/pc/arrow_rgt@2x.png);
      background-repeat: no-repeat;
      background-size: 28px 28px;
      margin-left: 10px;
    }
    .swiper-num {
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      .red {
        color: #e30808;
      }
    }
    .imgpre-close {
      position: absolute;
      top: 26px;
      right: 20px;
      width: 28px;
      height: 28px;
      background-image: url(../assets/pc/guanbi@2x.png);
      background-repeat: no-repeat;
      background-size: 28px 28px;
      cursor: pointer;
    }
  }
  .swiper-container {
  }
  .img-wrap img {
    width: 100%;
  }
  .pc-opera {
    width: 100%;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 26px 20px 15px;
    z-index: 9;
    box-sizing: border-box;
  }
  .h5-show {
    display: none;
  }
}
//移动处理
@media screen and (max-width: 750px) {
    .m-img-preview {
    .img-mask {
      background: #000;
    }
    .h5-imgpre-close {
      display: block;
      position: fixed;
      top: 35px;
      left: 15px;
      width: 15px;
      height: 15px;
      background-image: url(../assets/mobile/guanbi@2x.png);
      background-size: 15px 15px;
      cursor: pointer;
    }
    .img-contbox {
      width: 100%;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      .pc-opera {
        display: none;
      }
    }
    .h5-opera {
      display: block;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 23px 12px;
      background-color: #000;
      box-sizing: border-box;
      .img-intro {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }
      .sj-intro {
        margin-top: 10px;
        .avtar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .rit {
          margin-left: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          .text {
            max-width: 160px;
          }
        }
        .btn {
          width: 80px;
          height: 30px;
          background: linear-gradient(90deg, #14e1ff, #0b96f8);
          box-shadow: 0px 0px 10px 0px rgba(81, 81, 81, 0.48);
          border-radius: 15px;
          text-align: center;
          line-height: 30px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          margin-top: 2px;
        }
      }
    }
  }
}
</style>
