<template>
    <div class="m-circlelist-wrap" >
        <infinite-scroll :reserve="reserve" @Pullupload="loadmore" :finish="finish" :forbid="forbid">
            <div class="cir-item" v-for="item in circleArray" :key='item.id'>
                <router-link :to="'/dynamDetails?dynamic_id='+item.id" >
                    <div class="cir-hed">
                        <div class="avtar">
                            <img v-lazy="item.head_img" />
                        </div>
                        <div class="right">
                            <div class="nickname f-f0">
                                <span class="txt  f-ibm">{{item.name}}</span>
                                <i class="icon-shang indet f-ibm"></i>
                                <i class="icon-zuan indet f-ibm" v-if="item.vip==2"></i>
                            </div>
                            <div class="timer">{{item.generate_time}}</div>
                        </div>
                    </div>
                    <div class="cir-cont">
                        <div class="desc-txt">
                            <div class="txt">{{item.title}}</div>
                            <div class="more">[更多]</div>
                        </div>
                        <div class="img-wrap pc-wrap">
                            <!-- pc 图片列表-->
                                <div class="img-group pc-group f-ibm" v-for="(imgItem,index) in item.photo" :key="index+'p'" @click.prevent="handleimgpreFun(item)">
                                        <div class="img" v-if="index<=5" >
                                            <img  v-lazy="imgItem" />
                                            <div class="mask" :data-type="Number(index)|imgMore" v-if="item.photo.length>6" >
                                                <span>+{{item.photo.length-6}}</span>
                                            </div>
                                        </div>
                                    </div>
                                <!-- pc 图片列表 end-->
                        </div>
                        <div class="img-wrap h5-wrap">
                            <!-- h5 图片列表-->
                            <div class="img-group h5-group  f-ibm" v-for="(imgItem,index) in item.photo" :key="index+'m'" @click.prevent="handleimgpreFun(item)">
                                    <div class="img" v-if="index<=2" >
                                        <img v-lazy="imgItem" />
                                        <div class="mask" :data-type="Number(index)|h5imgMore" v-if="item.photo.length>3" >
                                            <span>+{{item.photo.length-3}}</span>
                                        </div>
                                    </div>
                                </div>
                            <!-- h5 图片列表 end-->
                        </div>
                    </div>
                </router-link>
                <div class="cir-btm">
                    <div class="tag f-f0" v-if="item.customize_label_name&&item.customize_label_name.length>0">
                        <div class="it f-ibm" v-for="(tag,index) in (item.customize_label_name)" :key="index">{{tag}}</div>
                    </div>
                    <div class="local f-f0" v-if="item.position!=''">
                        <i class="icon-local f-ibm"></i>
                        <span class="txt f-ibm">{{item.position}}</span>
                    </div>
                    <div class="opear">
                        <div class="commit grd">{{item.dynamic_num.comment_count}}</div>
                        <div class="coll grd">{{item.dynamic_num.collection_count}}</div>
                        <div class="like grd">{{item.dynamic_num.like_count}}</div>
                        <div class="red grd">{{item.dynamic_num.browse_count}}</div>
                    </div>
                </div>
            </div>
            <template slot="loading" >
                <div class="scroll-pag">
                    <div class="sp-tip"  ><i class="el-icon-loading"></i><span>正在加载</span></div>
                </div>
            </template>
            
            <div class="cir-pag" v-if="!(currpage==1&&circleArray.length<=0)">
                <list-pagination :pageCount="totalpage" @currentPageChange="handlepage" :currentPage="currpage"></list-pagination>
            </div>
            
            <template slot="nomore"  >
                <div class="scroll-pag" >
                    <div class="sp-tip"  ><span>- 暂无更多数据~ - </span></div>
                </div>
            </template>
       </infinite-scroll>
       <img-preview :imgPrevie='imgArray' :isshowdon="openimgPre"  @closePreview="closePreview"></img-preview>
    </div>
</template>

<script>
import listPagination from "../components/listPagination";
import imgPreview from '../components/imgPreview';
import infiniteScroll from '../components/infinite-scroll';
import {URL,axios} from "../api/index";
// 分割处理
 function strCutFun(data,str){
     var _data = data;
     return _data.split(str);
 }
 //获取宽度
 var windowWidth = 0;
export default {
    name: "circleList",
    components:{
        listPagination,
        imgPreview,
        infiniteScroll
    },
    props:{
        isdefault:{
            type:Boolean,
            default:true
        },
        listData:{
            type:Array,
            default: () => []
        },
        parmas:{
            type:Object,
            default: () => {}
        }
    },
    filters:{
        imgMore:function(num){
           return (num>4&&num==5)?"1":"0";
        },
        h5imgMore:function(num){
          return (num>1&&num==2)?"1":"0";
        }
    },
    data(){
        return{
            openimgPre:false, //打开图片预览
            scrollHeight:'auto',
            currpage:1,      //默认当前页
            totalpage:10000,  //总页数
            circleArray:[],  //列表数据
            ismoreload:true, //触底加载
            imgArray:{},      //当前图片预览数据
            //触底加载
            reserve:204,       //加载预留高度
            finish:false,
            forbid:false,       //是否禁止使用
        }
    },
    created(){
        if(!this.isdefault) return;
        //是否默认加载
        this.upArraydata();
        this.initUi();
    },
    mounted(){
        this.reserve = this.$store.state.footerHeight;
         window.onresize = ()=>{
           this.initUi();
        };
    },
    watch:{
        //参数监听
        'parmas':function(oldData,newData){
            console.log(oldData,newData)
            this.upArraydata();
            this.initUi();
        },
        deep:true
    },
    computed:{
    },
    methods:{
        //加载数据
       async upArraydata(){
            //获取参数
            var data = this.parmas.data;
            Object.assign(data,{page:this.currpage});
            //接口是否存在
            if(!this.parmas.api) return;
            //开始请求
            var _that = this;
            var resp = await axios({
                    url: _that.parmas.api,
                    method: 'get',
                    headers: {
                        'http-platform': 'h5',
                        'http-total': 1,
                        'http-random': 1,
                        'http-version': 1,
                    },
                    params:data
                });
            if(resp.status==200&&resp.data.code==1){

               var _array = resp.data.data;
               //是否加载全部数据
               if(_array.length<=0){
                   _that.finish = true;
                   return;
               }
               var _newary = [];
               //数据处理
               _newary = _array.filter((item,index)=>{
                   if(item.customize_label_name!=''&&item.customize_label_name.length>0){
                       item.customize_label_name = strCutFun(item.customize_label_name,',');
                   }
                   item.photo = strCutFun(item.photo,'|');
                   return item;
               });
               //移动追加数据
               if(windowWidth<=750){
                    _that.circleArray = _that.circleArray.concat(_newary)
               }else{
                   _that.circleArray = _newary;
               }
                
               //strCutFun
            }else{
                 _that.$message.error("请求失败！")
            }
        },
        // 页面初始化
        initUi(){
            //获取宽度（是否触底加载）
            windowWidth =document.documentElement.clientWidth || document.body.clientWidth;
            if(windowWidth<=750){
                this.forbid = false
            }else{
                this.forbid =true;
            }
        },
        //pageing 当前页改变
        handlepage(page){
            console.log(page)
            this.currpage = page;
            this.upArraydata();
        },
        moreload(){
            //是否第一页
            console.log('触底')
            //重新设置高度

        },
        //预览事件
        handleimgpreFun(data){
            this.openimgPre = true;
            this.imgArray = data;
        },
        //预览关闭回调事件
        closePreview(){
            this.openimgPre = false;
        },
        //上拉加载回调事件
        loadmore(){
            if(this.finish) return;
            this.currpage+=1;
            this.upArraydata();
        }
    }
};
</script>

<style lang="less" scoped>
.m-circlelist-wrap{
    background: #F5F5F5;
    .cir-item{
        background-color: white;
        padding: 20px;
        box-sizing:border-box;
         &+.cir-item{
             margin-top: 24px;
        }
        .cir-hed{
            width: 100%;
            position: relative;
            .avtar{
                width: 48px;
                height: 48px;
                overflow: hidden;
                border-radius: 50%;
                img{
                    width: 100%;
                    min-height: 100%;
                    border-radius: 50%;
                }
            }
            .right{
                position: absolute;
                top: 0;
                left: 0;
                margin-left: 60px;
                .nickname{
                    .txt{
                        font-size: 16px;
                        font-weight: bold;
                        color: #E61414;
                        font-family: PingFang SC;
                    }
                    .icon-shang{
                        width: 18px;
                        height: 18px;
                        background: url(../assets/pc/seller/shang@2x.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    .icon-zuan{
                        width: 18px;
                        height: 18px;
                        background: url(../assets/pc/seller/zuan@2x.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    .indet{
                        margin-left: 5px;
                    }
                }
                .timer{
                    font-size: 12px;
                    font-weight: 400;
                    color: #AAAAAA;
                    margin-top: 8px;
                    font-family: PingFang SC;
                }
            }
        }
        .cir-cont{
            .desc-txt{
                width: 100%;
                position: relative;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                line-height: 30px;
                max-height: 60px;
                overflow: hidden;
                margin-top: 13px;
                font-family: PingFang SC;
                .more{
                    position: absolute;
                    right: 0;
                    top: 30px;
                    color: #F02420;
                    cursor: pointer;
                    background-color: white;
                    padding-left: 25px;
                }
            }
            .h5-wrap{
                display: none;
            }
            .img-group{
                box-sizing: border-box;
            }
            .pc-wrap{
                margin-left: -16px;
            }
            .img-group:nth-of-type(-n + 6){
                padding-left: 16px;
            }
            .img{
                position: relative;
                margin-top: 17px;
                max-width: 180px;
                max-height: 180px;
                overflow: hidden;
                cursor: pointer;
                border-radius: 8px;
                img{
                    width: 100%;
                }
                &+.img{
                    margin-left: 16px;
                }
                .mask{
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    font-size: 30px;
                    font-weight: 400;
                    color: #FFFFFF;
                    background: rgba(0, 0, 0, .5);
                    text-align: center;
                    span{
                        display: table-cell;
                        vertical-align: middle;
                    }
                    &[data-type='1']{
                      display: table;
                    }
                }
            }
            .last{

            }
        }
        .cir-btm{
            .tag{
                margin-top: 22px;
                .it{
                    padding: 0 6px;
                    box-sizing: border-box;
                    height: 28px;
                    line-height: 28px;
                    background: linear-gradient(90deg, #4FABFE, #4174FE);
                    opacity: 0.5;
                    border-radius: 4px;
                    font-size: 15px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-right: 12px;
                    cursor: pointer;
                }

            }
            .local{
                margin-top: 13px;
                .icon-local{
                    width: 12px;
                    height: 16px;
                    background: url(../assets/pc/seller/location@2x.png) no-repeat;
                    background-size: 12px 16px;
                }
                .txt{
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #CCCCCC;
                }
            }
            .opear{
                display: table;
                width: 100%;
                margin-top: 20px;
                .grd{
                    width: 25%;
                    box-sizing:border-box;
                    display: table-cell;
                    height: 24px;
                    line-height: 24px;
                    text-align: left;
                    font-family: Microsoft YaHei;
                    vertical-align: middle;
                    font-size: 14px;
                    font-weight: 400;
                    color: #B9B9B9;
                    padding-left: 38px;
                }
                .commit{
                    background: url(../assets/pc/seller/pinglun@2x.png) left center no-repeat;
                    background-size: 24px 24px;
                }
                .coll{
                    background: url(../assets/pc/seller/shoucang@2x.png) left center no-repeat;
                    background-size: 24px 24px;
                    cursor: pointer;
                    &.active{
                        background: url(../assets/pc/seller/shoucang_press@2x.png) left center no-repeat;
                        background-size: 24px 24px;
                    }
                }
                .like{
                    background: url(../assets/pc/seller/zan@2x.png) left center no-repeat;
                    background-size: 24px 24px;
                    cursor: pointer;
                    &.active{
                        background: url(../assets/pc/seller/zan_press@2x.png) left center no-repeat;
                        background-size: 24px 24px;
                    }
                }
                .red{
                    background: url(../assets/pc/seller/liulan@2x.png) left center no-repeat;
                    background-size: 24px 24px;
                }

            }
        }

    }
    .cir-pag{
        padding: 43px 0 30px;
        background-color: white;
    }
    .scroll-pag{
        display: none;
    }
}

//移动处理
@media screen and (max-width:750px) { 
    
    .m-circlelist-wrap{
        .cir-item{
            padding: 18px 10px;
            .cir-hed{
            width: 100%;
            position: relative;
                .avtar{
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 50%;
                    img{
                        width: 100%;
                        min-height: 100%;
                        border-radius: 50%;
                    }
                }
                .right{
                    margin-left: 50px;
                    .nickname{
                        .txt{
                            font-size: 15px;
                            line-height: 15px;
                        }
                        .timer{
                            font-size: 11px;
                        }
                        .icon-shang,.icon-zuan{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
            .cir-cont{
                .h5-wrap{
                    display: inline-block;
                    margin-left: -15px;
                    font-size: 0;
                    .h5-group{
                        padding: 0;
                        width: 0;
                    }
                    .h5-group:nth-of-type(-n + 3){
                        padding-left: 15px;
                        width: 33.33333%;
                    .img{
                        max-width: 100%;
                        min-width: 100%;
                        .mask span{
                            font-size: 18px;
                        }
                    }
                    }
                }
                .img-group{

                }
                .pc-wrap{
                    display: none;
                }
                .desc-txt{
                        font-size: 14px;
                        line-height: 20px;
                        max-height: 38px;
                    .text{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .more{
                        display: none;
                    }
                }
            }
            .cir-btm{
                .tag{
                    margin-top: 13px;
                    .it{
                        font-size: 10px;
                        line-height: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
                .opear{
                    .grd,.active.grd{
                        background-size: 16px 16px;
                        padding-left: 24px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }


        }
        .cir-item + .cir-item{
            margin-top: 8px;
        }
        .cir-pag{
            display: none;
        }
        .scroll-pag{
            display: block;
            text-align: center;
            padding: 15px 0;
            background-color: white;
            margin-top: 8px;
            .sp-tip{
                display: inline-block;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #959595;
                padding-left: 6px;
            }
        }
    }

}
// @media screen and(min-width: 751px) and (max-width: 1109px){ 

// }
</style>
