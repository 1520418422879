<template>
	<div class="m-list-pagination">
        <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="pageSizes" prev-text="上一页" next-text="下一页" :page-size="pageSize" :current-page="currentPage" background  layout="prev, next, jumper" :pageCount="pageCount">
        </el-pagination>
	</div>
</template>

<script>
	export default {
		name: 'listPagination',
        props:{
            pageSize:{
                type:Number,
                value:10
            },
            pageSizes:{
                type:Array,
                default: () => [10,20,50,100]
            },
            pageCount:{
                type:Number,
                default:1
            },
            currentPage:{
                type:Number,
                default:1
            }
        },
		data() {
			return {
                
			};
		},
		methods: {
            handleCurrentChange(val){  //当前也改变时
                //this.currentPage = val;
                this.$emit("currentPageChange",val);
            },
            handleSizeChange(val){  //改变每页大小
                this.currentPage = 1;
				//this.pageSize = val;
                //重新加载数据
                this.$emit("sizeChange",val);
            }
		},
	}
</script>
<style lang="less">
.m-list-pagination{
    margin-bottom: 20px;
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #F02420;
    }
    .el-pagination button{
        padding: 0 10px !important;
    }
}

</style>

